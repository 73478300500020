import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as RouterRoutes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Home from "./pages/home/Home";
import HowitWorks from "./pages/howitworks/HowItWorks";
import FAQ from "./pages/faq/FAQ";
import AboutUs from "./pages/aboutus/AboutUs";
import Pricing from "./pages/pricing/Pricing";
import Samples from "./pages/samples/Samples";
import Order from "./pages/order/Order";
import OnlineClassHelp from "./pages/onlineclasshelp/OnlineClassHelp";
import NotFound from "./pages/404/NotFound";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import TermsandConditions from "./pages/termsandconditions/TermsandCondtions";
import Blog from "./pages/blog/Blog";
import BlogItem from "./pages/blog/BlogItem";
import usePageTitle from "./components/utils/pagetitle";
import Questions from "./pages/questions/Questions";
import QuestionItem from "./pages/questions/QuestionItem";

function Page(props) {
  const location = useLocation();
  const canonicalUrl = `https://www.essaypearl.com${location.pathname}`;
  const titlePrefix = " | ESSAYPEARL";
  usePageTitle(`${props.title}${titlePrefix}`);
  return (
    <div>
      <Helmet>
        <title>
          {props.title}{titlePrefix}
        </title>
        <link rel="canonical" href={canonicalUrl} />
        <meta
          name="description"
          content={
            props.description ||
            "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          name="keywords"
          content={
            props.keywords ||
            "Take my Online Class Help, Take my Exam Help, Custom Writing Services, Professional Essay Writers, Professional Essay Writers, 24/7 Customer Support, Take my Quiz and Test, Do My Homework, Proctored Exams Service"
          }
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="ESSAYPEARL" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website"></meta>
        <meta content="summary_large_image" name="twitter:card"></meta>
        <meta
          property="og:title"
          content={
            props.title ||
            "Your Personal Essay Writer. Use our professional writing service and receive: ✓High-Quality Papers ✓Plagiarism Reports ✓Punctual Delivery ✓24/7 Support. Sign Up Today!"
          }
        />
        <meta
          property="og:description"
          content={
            props.desc ||
            "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="og:image"
          content={props.image || "https://new.essaypearl.com/assets/logo.jpg"}
        />
        <meta
          property="twitter:title"
          content={
            props.title || "Essay writing, Essay services, Writing services"
          }
        />
        <meta
          property="twitter:description"
          content={
            props.desc ||
            "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="twitter:image"
          content={props.image || "https://new.essaypearl.com/assets/logo.jpg"}
        />
      </Helmet>
      {props.content}
    </div>
  );
}
function AppRoutes() {
  return (
    <RouterRoutes>
      <Route path="/" element={<Page title="Home" content={<Home />} />} />
      <Route
        path="/howitworks"
        element={<Page title="How It Works" content={<HowitWorks />} />}
      />
      <Route path="/faqs" element={<Page title="FAQs" content={<FAQ />} />} />
      <Route
        path="/aboutus"
        element={<Page title="About Us" content={<AboutUs />} />}
      />
      <Route
        path="/pricing"
        element={<Page title="Pricing" content={<Pricing />} />}
      />
      <Route
        path="/samples"
        element={<Page title="Samples" content={<Samples />} />}
      />
      <Route
        path="/order/:academicLevel?/:typeOfPaper?/:deadline?/:pageCount?"
        element={<Page title="Create Order" content={<Order />} />}
      />

      <Route
        path="/onlineclasshelp"
        element={
          <Page title="Online Class Help" content={<OnlineClassHelp />} />
        }
      />
      <Route
        path="/register"
        element={<Page title="Register" content={<Register />} />}
      />
      <Route
        path="/login"
        element={<Page title="Login" content={<Login />} />}
      />
      <Route path="/blog" element={<Page title="Blog" content={<Blog />} />} />
      <Route path="/blog/:postid" element={<BlogItemPage />} />
      <Route
        path="/questions"
        element={<Page title="Questions" content={<Questions />} />}
      />
      <Route path="/questions/:questionid" element={<QuestionsItemPage />} />
      <Route
        path="/dashboard/:dashpage?/:dashorder?/:dashaction?"
        element={<Page title="Dashboard" content={<Dashboard />} />}
      />
      <Route
        path="/termsandconditions"
        element={
          <Page
            title="Terms  and Conditions"
            content={<TermsandConditions />}
          />
        }
      />
      <Route
        path="*"
        element={<Page title="404 Page Not Found" content={<NotFound />} />}
      />
      {/* Add more routes as needed */}
    </RouterRoutes>
  );
}

const BlogItemPage = () => {
  // const { postname } = useParams();
  return <Page title="Blog Post" content={<BlogItem />} />;
};

const QuestionsItemPage = () => {
  // const { questionname } = useParams();
  return <Page title="Question Item" content={<QuestionItem />} />;
};

export const routePaths = [
  "/",
  "/howitworks",
  "/faqs",
  "/aboutus",
  "/pricing",
  "/samples",
  "/onlineclasshelp",
  "/register",
  "/login",
  "/blog",
  "/questions",
  "/termsandconditions",
];

export default AppRoutes;
