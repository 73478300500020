import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";
import CalculatorForm from "../../components/forms/CalculatorForm";
import RecentBlog from "../widgets/RecentBlogs";

const SideBarPageLayout = ({ pageTitle, children }) => {
  return (
    <div>
      <CustomBreadCrump pagetitle={pageTitle} />
      <div className="standard-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mb-2">{children}</div>
            <div className="col-md-3 mb-2">
              <div className="sb-sticky-top">
                <CalculatorForm />
                <RecentBlog />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarPageLayout;
