import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import "./blog.css";
import { useParams } from "react-router-dom";
import blogImg from "../../images/blog.jpg";
import blogAuthorImg from "../../images/author.png";
import ReactHtmlParser from "react-html-parser"; // Import ReactHtmlParser to parse HTML content
import { Helmet } from "react-helmet-async"; // Import Helmet for dynamic titles
import { formatDate } from "../../components/widgets/Config";
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;
const apiUrl = process.env.REACT_APP_APIURL;

const BlogItem = () => {
  const { postid } = useParams();
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState(null); // Change to null initially

  const formatId = (postid) => {
    if (!postid) return "";
    return parseInt(postid);
  };

  const postCleanId = formatId(postid);

  useEffect(() => {
    fetch(`${apiUrl}web/getblogitem/${postCleanId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.b_title) {
          const extractedBlog = {
            id: data.blog_id,
            title: data.b_title,
            image: data.b_image
              ? `${fileBaseUrl}blog/${data.b_image}`
              : blogImg,
            author: data.b_author,
            authorImage: data.b_authorimage
              ? `${fileBaseUrl}blog/${data.b_authorimage}`
              : blogAuthorImg,
            dateAdded: data.b_dateadded,
            content: data.b_content,
            tags: JSON.parse(data.b_tags),
            desc:data.b_desc,
            keywords:data.b_keywords,
          };
          setBlog(extractedBlog);
        } else {
          setBlog(null);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog info:", error);
        setLoading(false);
      });
  }, [postCleanId]);

  

  return (
    <div>
      {/* Use Helmet to dynamically set the page title */}
      <Helmet>
        <title>{blog ? blog.title : "Loading..."} | ESSAYPEARL</title>
        <meta
          name="description"
          content={
            blog
              ? blog.desc
              : "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          name="keywords"
          content={
            blog
              ? blog.keywords
              : "Take my Online Class Help, Take my Exam Help, Custom Writing Services, Professional Essay Writers, Professional Essay Writers, 24/7 Customer Support, Take my Quiz and Test, Do My Homework, Proctored Exams Service"
          }
        />
        <meta
          property="og:title"
          content={
            blog
              ? blog.title
              : "Your Personal Essay Writer. Use our professional writing service and receive: ✓High-Quality Papers ✓Plagiarism Reports ✓Punctual Delivery ✓24/7 Support. Sign Up Today!"
          }
        />
        <meta
          property="og:description"
          content={
            blog
              ? blog.desc
              : "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="og:image"
          content={
            blog ? blog.image : "https://new.essaypearl.com/assets/logo.jpg"
          }
        />
        <meta
          property="twitter:title"
          content={
            blog
              ? blog.title
              : "Your Personal Essay Writer. Use our professional writing service and receive: ✓High-Quality Papers ✓Plagiarism Reports ✓Punctual Delivery ✓24/7 Support. Sign Up Today!"
          }
        />
        <meta
          property="twitter:description"
          content={
            blog
              ? blog.desc
              : "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="twitter:image"
          content={
            blog ? blog.image : "https://new.essaypearl.com/assets/logo.jpg"
          }
        />
      </Helmet>

      <SideBarPageLayout pageTitle={postCleanId}>
        {loading ? (
          <p>Loading...</p>
        ) : blog ? (
          <div>
            <img src={blog.image} className="blogitem-img" alt={blog.title} />
            <div className="row mt-2">
              <div className="col-md-8">
                <h2>{blog.title}</h2>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-6 float-right">
                    <img
                      src={blog.authorImage}
                      className="blogauthoritem-img"
                      alt={blog.author}
                    />
                  </div>
                  <div className="col-md-6">
                    <p className="fw-bold text-primary mb-0 mt-1">
                      {blog.author}
                    </p>
                    <p className="text-small text-muted">
                      {formatDate(blog.dateAdded)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="mt-2">{ReactHtmlParser(blog.content)}</div>
            <div className="mt-2">
              <h3>Tags:</h3>
              <div>
                {blog.tags.map((tag, index) => (
                  <span key={index} className="badge bg-secondary mr-2">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="alert alert-warning">
              Sorry, the article you are looking for could not be found
            </div>
            <a
              href="/blog"
              className="default-btn active"
              style={{ backgroundColor: "green" }}
            >
              View More Articles
            </a>
          </div>
        )}
      </SideBarPageLayout>
    </div>
  );
};

export default BlogItem;
