import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const apiUrl = process.env.REACT_APP_APIURL;
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;

const RecentBlog = () => {
  const [loading, setLoading] = useState(true);
  const [rblogs, setRBlogs] = useState([]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  useEffect(() => {
    fetch(`${apiUrl}web/getrecentblogs`)
      .then((response) => response.json())
      .then((data) => {
        if (data ) {
          const extractedBlogs = data.map((item) => ({
            id: item.blog_id,
            title: item.b_title,
            dateAdded: item.b_dateadded, // corrected to item.b_dateadded
          }));
          setRBlogs(extractedBlogs);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="mt-2">
      <p>Recent Blog Posts</p>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {rblogs.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              We have not published any new blogs yet; please check back later.
            </div>
          ) : (
            <ul className="list-group list-group-flush">
              {rblogs.map((item) => (
                <Link
                  key={item.id}
                  className="nodecoration"
                  to={`/blog/${item.id}`}
                >
                  <li className="list-group-item fs-6">
                    {item.title}
                    <br></br>
                    <span className="text-small">
                      {formatDate(item.dateAdded)}
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default RecentBlog;
