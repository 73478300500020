import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import certified from "../../images/certified.png";
import "./questions.css";
import { Link, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser"; // Import ReactHtmlParser to parse HTML content
import { Helmet } from "react-helmet-async"; // Import Helmet for dynamic titles

const apiUrl = process.env.REACT_APP_APIURL;

const QuestionItem = () => {
  const { questionid } = useParams();
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState(null);

  const formatId = (questionid) => {
    if (!questionid) return ""; 
    return parseInt(questionid);
  };

  const postCleanId = formatId(questionid);

  useEffect(() => {
    if (postCleanId) {
      fetch(`${apiUrl}web/getquestionitem/${postCleanId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data && data.q_name) {
            const extractedQuestion = {
              id: data.question_id,
              title: data.q_name,
              school: data.q_school,
              course: data.q_course,
              department: data.q_department,
              content: data.q_description,
              dateAdded: data.q_dateadded,
              tags: JSON.parse(data.q_tags),
              desc: data.q_desc,
              keywords: data.q_keywords,
            };
            setQuestion(extractedQuestion);
          } else {
            setQuestion(null);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching question info:", error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setQuestion(null);
    }
  }, [postCleanId]);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  return (
    <div>
      {/* Use Helmet to dynamically set the page title */}
      <Helmet>
        <title>{question ? question.title : "Loading..."} | ESSAYPEARL</title>
        <meta
          name="description"
          content={
            question
              ? question.desc
              : "!Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          name="keywords"
          content={
            question
              ? question.keywords
              : "Take my Online Class Help, Take my Exam Help, Custom Writing Services, Professional Essay Writers, Professional Essay Writers, 24/7 Customer Support, Take my Quiz and Test, Do My Homework, Proctored Exams Service"
          }
        />
        <meta
          property="og:title"
          content={
            question
              ? question.title
              : "Your Personal Essay Writer. Use our professional writing service and receive: ✓High-Quality Papers ✓Plagiarism Reports ✓Punctual Delivery ✓24/7 Support. Sign Up Today!"
          }
        />
        <meta
          property="og:description"
          content={
            question
              ? question.desc
              : "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="og:image"
          content={
            question
              ? question.image
              : "https://new.essaypearl.com/assets/logo.jpg"
          }
        />
        <meta
          property="twitter:title"
          content={
            question
              ? question.title
              : "Your Personal Essay Writer. Use our professional writing service and receive: ✓High-Quality Papers ✓Plagiarism Reports ✓Punctual Delivery ✓24/7 Support. Sign Up Today!"
          }
        />
        <meta
          property="twitter:description"
          content={
            question
              ? question.desc
              : "Expert help for online classes, essay writing services, and 24/7 support for exams, quizzes, and homework to enhance your academic success"
          }
        />
        <meta
          property="twitter:image"
          content={
            question
              ? question.image
              : "https://new.essaypearl.com/assets/logo.jpg"
          }
        />
      </Helmet>
      <SideBarPageLayout pageTitle={postCleanId || "Question Details"}>
        {loading ? (
          <p>Loading...</p>
        ) : question ? (
          <div>
            <div className="row mt-2">
              <div className="col-md-12">
                <h2>{question.title}</h2>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <p className="text-small">
                      School<br></br>
                      <span className="text-primary">{question.school}</span>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p className="text-small">
                      Course<br></br>
                      <span className="text-primary">{question.course}</span>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p className="text-small">
                      Department<br></br>
                      <span className="text-primary">
                        {question.department}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p className="text-small">
                      Date Posted<br></br>
                      <span className="text-primary">
                        {formatDate(question.dateAdded)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="mt-2">{ReactHtmlParser(question.content)}</div>
            <div className="view-answer-section">
              <div className="answer-box">
                <div>
                  <img
                    src={certified}
                    className="boximg"
                    alt="Certified Guranteed"
                  />
                  <p className="student-helped">15 MILLION STUDENTS HELPED!</p>
                  <p>Sign up to view the full answer</p>
                  <Link
                    to="/register"
                    className="view-answer-button"
                    style={{ textDecoration: "none" }}
                  >
                    VIEW EXPLANATION AND ANSWER
                  </Link>
                </div>
              </div>
            </div>
            {question.tags && question.tags.length > 0 && (
              <div className="mt-2">
                <h3>Tags:</h3>
                <div>
                  {question.tags.map((tag, index) => (
                    <span key={index} className="badge bg-secondary mr-2">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="alert alert-warning">
              Sorry, the question you are looking for could not be found.
            </div>
            <a
              href="/questions"
              className="default-btn active"
              style={{ backgroundColor: "green" }}
            >
              View More Questions
            </a>
          </div>
        )}
      </SideBarPageLayout>
    </div>
  );
};

export default QuestionItem;
