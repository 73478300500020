import React, { useState, useEffect } from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import "./questions.css";
import blogImg from "../../images/blog.jpg";
import { Link } from "react-router-dom";
const apiUrl = process.env.REACT_APP_APIURL;
const fileBaseUrl = process.env.REACT_APP_FILEBASEURL;

const Questions = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 25;
  const maxVisiblePages = 10; 

  useEffect(() => {
    fetch(`${apiUrl}web/getquestions?page=${currentPage}&limit=${perPage}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          const extractedQuestions = data.data.map((item) => ({
            id: item.question_id,
            title: item.q_name,
            department: item.q_department,
            course: item.q_course,
            school: item.q_school,
          }));
          console.log(extractedQuestions);
          setQuestions(extractedQuestions);
          setTotalPages(data.totalPages);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
        setLoading(false);
      });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= maxVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrentPage = Math.floor(maxVisiblePages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxVisiblePages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <SideBarPageLayout pageTitle="Blog">
        <h2>24/7 Homework Q&A</h2>
        <p>
          Get "questions" help — Post your "questions" homework questions and
          get answers from qualified tutors.
        </p>
        <hr></hr>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div>
            {questions.length === 0 ? (
              <div className="alert alert-warning" role="alert">
                We have not published any questions yet please check back later.
              </div>
            ) : (
              <div>
                <ul className="list-group list-group-flush">
                  {questions.map((item) => (
                    <Link
                      className="questionlink"
                      to={`/questions/${item.id}`}
                      key={item.id}
                    >
                      <li className="list-group-item">
                        <h6>{item.title}</h6>
                        <p className="text-small text-primary">
                          <span className="badge bg-primary mr-2">
                            {item.school}
                          </span>
                          <span className="badge bg-secondary">
                            {item.course}
                          </span>
                          <span className="badge bg-secondary">
                            {item.department}
                          </span>
                        </p>
                      </li>
                    </Link>
                  ))}
                </ul>
                <div className="mt-2">
                  <nav aria-label="Page navigation mt-2">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        )}
      </SideBarPageLayout>
    </div>
  );
};

export default Questions;
